<template>
	<div>
		<!-- <v-sheet class="recurring-main-listing"> -->
		<v-layout class="mb-1">
			<v-row class="d-flex align-center">
				<v-select
					v-model="filter.month"
					class="listing-select pr-0"
					hide-details
					:disabled="pageLoading"
					:items="monthList"
					solo
					item-text="title"
					item-value="value"
					style="max-width: 175px !important; min-width: 175px !important"
					:menu-props="{ offsetY: true, bottom: true }"
					@change="getmapExcel()"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.title"></v-list-item-title>
						</v-list-item-content>
					</template>
				</v-select>
				<v-select
					v-model="filter.year"
					class="listing-select year"
					hide-details
					:disabled="pageLoading"
					:items="yearList"
					solo
					item-text="title"
					item-value="value"
					style="max-width: 110px !important; min-width: 110px !important"
					:menu-props="{ offsetY: true, bottom: true }"
					@change="getmapExcel()"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.title"></v-list-item-title>
						</v-list-item-content>
					</template>
				</v-select>

				<v-text-field
					right
					class="ml-3"
					ref="search"
					v-model.trim="filter.search"
					autocomplete="off"
					hide-details
					placeholder="search."
					outlined
					@keydown.esc="onEsc"
					@keydown.enter="getURLMapping()"
					@input="searchKeyword"
					style="max-width: 280px; min-width: 250px"
				>
					<template #prepend-inner>
						<v-icon color="grey" class="ml-1 mr-2"> mdi-magnify </v-icon>
					</template>
				</v-text-field>
				<v-btn
					depressed
					color="white"
					class="blue darken-4"
					tile
					text
					small
					style="height: 30px"
					@click="getURLMapping()"
				>
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
				<v-btn
					depressed
					color="white"
					class="red darken-4 ml-2"
					tile
					text
					small
					style="height: 30px; min-width: 0px !important"
					v-on:click="resetSearch"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-row>
			<v-spacer></v-spacer>
			<v-flex class="py-0 my-auto d-flex justify-end text-right listing-right">
				<!-- <template> -->
				<!-- <v-flex class="d-flex justify-content-end mr-1"> </v-flex> -->
				<!-- <v-flex class="d-flex justify-content-end mr-1">
							<DatePicker
								hide-details
								outlined
								custom-class="mt-0"
								v-model="actula_date_filter"
								placeholder="Actual Date"
								v-on:change="actualDateFilter($event)"
							>
							</DatePicker>
						</v-flex>
	 -->
				<!-- <template> -->
				<template v-if="getPermission('url_mapping:create')">
					<v-tooltip
						top
						v-if="
							AllowAnyOforGetRoleNType([
								'master',
								'admin',
								'manager',
								'super_admin',
								'team_lead',
								'ateam_lead',
								'on_page',
							])
						"
					>
						<template #activator="{ on, attrs }">
							<v-btn
								depressed
								:disabled="pageLoading"
								tile
								color="blue darken-2"
								class="text-white"
								v-on="on"
								v-bind="attrs"
								@click="fetchStatus()"
							>
								<v-icon left> mdi-reload </v-icon>
								Fetch Status
							</v-btn>
						</template>
						<span>Fetch Status</span>
					</v-tooltip>

					<v-tooltip top>
						<template #activator="{ on, attrs }">
							<v-btn
								depressed
								:disabled="pageLoading"
								tile
								color="blue darken-2"
								class="text-white"
								v-on="on"
								v-bind="attrs"
								href="/media/sample-files/Sample_url_mapping.xlsx"
								download
							>
								<v-icon left> mdi-download </v-icon>
								Sample CSV
							</v-btn>
						</template>
						<span>Download Sample CSV File</span>
					</v-tooltip>

					<v-tooltip
						top
						v-if="
							AllowAnyOforGetRoleNType([
								'master',
								'admin',
								'manager',
								'super_admin',
								'team_lead',
								'ateam_lead',
								'on_page',
							])
						"
					>
						<template #activator="{ on, attrs }"
							><v-btn
								depressed
								:disabled="pageLoading"
								tile
								color="blue darken-4"
								class="text-white"
								v-on:click="importDialog = true"
								v-on="on"
								v-bind="attrs"
							>
								<v-icon left> mdi-plus </v-icon>
								Upload File
							</v-btn>
						</template>
						<span>Upload CSV File</span>
					</v-tooltip>
				</template>

				<v-tooltip top>
					<template #activator="{ on, attrs }">
						<v-btn
							depressed
							:disabled="pageLoading"
							tile
							color="green darken-4"
							class="text-white"
							v-on="on"
							v-bind="attrs"
							@click="exportCSV()"
						>
							<!-- @click="doAction({ url: exportCSVUrl }, 'download')" -->
							<v-icon left> mdi-table-arrow-down </v-icon>
							CSV
						</v-btn>
					</template>
					<span>Download CSV File</span>
				</v-tooltip>
				<!-- </template> -->
				<!-- </template> -->
			</v-flex>
		</v-layout>
		<div style="max-height: calc(100vh - 297px); overflow-y: auto">
			<!-- :height="excelHeight" -->
			<div class="seo-tabs">
				<v-simple-table
					width="100%"
					fixed-header
					class="detail-table normal-table table-head-sticky mb-5"
				>
					<thead>
						<tr>
							<th width="5%" class="p-2 light-blue-bg custome-table">#</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">Mapping Code</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">
								Date
								<v-icon
									color="blue darken-4"
									right
									small
									v-if="mapExcel.length > 1"
									@click="sortBacklink('added_at', usort.order, 'usort')"
								>
									{{ usort.column == "added_at" ? usort.icon : "mdi-filter-variant" }}
								</v-icon>
							</th>
							<th width="5%" class="p-2 light-blue-bg custome-table">Action</th>
						</tr>
					</thead>
					<tbody v-if="mapExcel.length">
						<tr
							v-for="(row, index) in mapExcel"
							:class="['cursor-pointer', { 'grey lighten-3': row.id === activeRowId }]"
							@click="rowActive(row.id)"
							:key="row.name + '_' + index"
						>
							<td width="3%" class="p-2 border-top-light-grey">
								<!-- <ShowValue :object="row" object-key="id" label="sr"></ShowValue>
									{{ index+1 }} -->
								{{ index + 1 }}
							</td>
							<td width="20%" class="p-2 border-top-light-grey">
								<!-- <ShowValue :object="row" object-key="barcode" label="barcode" class="text-capitalize"></ShowValue> -->
								<Chip :text="row.barcode" color="cyan" v-if="row.barcode"></Chip>
							</td>
							<td width="20%" class="p-2 border-top-light-grey">
								<span>{{ formatDateTime(row.added_at) }}</span>
							</td>
							<td width="20%" class="p-2 border-top-light-grey">
								<v-tooltip right>
									<template #activator="{ on, attrs }">
										<v-btn
											v-on:click.stop="doAction(row, 'download')"
											icon
											depressed
											color="blue darken-4"
											class="mr-2"
											v-on="on"
											v-bind="attrs"
											:ripple="false"
										>
											<v-icon size="25">mdi-download</v-icon>
										</v-btn>
									</template>
									<span>{{ row.name }}</span>
								</v-tooltip>
								<template
									v-if="
										getPermission('url_mapping:delete') &&
										AllowAnyOforGetRoleNType([
											'master',
											'admin',
											'manager',
											'super_admin',
											'team_lead',
											'ateam_lead',
										])
									"
								>
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<v-btn v-bind="attrs" v-on="on" icon depressed @click="doAction(row, 'delete')">
												<v-icon size="25" class="cursor-pointer" color="red">mdi-delete </v-icon>
											</v-btn>
										</template>
										<span>{{ row.name }}</span>
									</v-tooltip>
								</template>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td :colspan="5">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There <span class="text-lowercase">are no file at the moment.</span>
								</p>
							</td>
						</tr>
					</tfoot>
				</v-simple-table>
			</div>

			<!-- :height="mappingHeight" -->
			<div class="table-detail">
				<v-simple-table width="100%" class="detail-table normal-table table-head-sticky">
					<thead>
						<tr>
							<th width="10%" class="p-2 light-blue-bg custome-table">#</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">
								Keyword
								<v-icon
									color="blue darken-4"
									right
									small
									v-if="urlMapping.length > 1"
									@click="sortBacklink('keyword', sort.order, 'sort')"
								>
									{{ sort.column == "keyword" ? sort.icon : "mdi-filter-variant" }}
								</v-icon>
							</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">Target URL</th>
							<th width="6%" class="p-2 light-blue-bg custome-table text-center">
								Status
								<v-icon
									color="blue darken-4"
									right
									small
									v-if="urlMapping.length > 1"
									@click="sortBacklink('status', sort.order, 'sort')"
								>
									{{ sort.column == "status" ? sort.icon : "mdi-filter-variant" }}
								</v-icon>
							</th>
							<th width="15%" class="p-2 light-blue-bg custome-table">
								Current Title
								<v-icon
									color="blue darken-4"
									right
									small
									v-if="urlMapping.length > 1"
									@click="sortBacklink('current_title', sort.order, 'sort')"
								>
									{{ sort.column == "current_title" ? sort.icon : "mdi-filter-variant" }}
								</v-icon>
							</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">
								Current Description
								<v-icon
									color="blue darken-4"
									right
									v-if="urlMapping.length > 1"
									small
									@click="sortBacklink('current_description', sort.order, 'sort')"
								>
									{{ sort.column == "current_description" ? sort.icon : "mdi-filter-variant" }}
								</v-icon>
							</th>
							<th width="22%" class="p-2 light-blue-bg custome-table">
								Current H1
								<v-icon
									color="blue darken-4"
									right
									small
									v-if="urlMapping.length > 1"
									@click="sortBacklink('current_h1', sort.order, 'sort')"
								>
									{{ sort.column == "current_h1" ? sort.icon : "mdi-filter-variant" }}
								</v-icon>
							</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="pageLoading">
							<tr>
								<td colspan="7" class="mx-20 my-40 text-center">
									<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
								</td>
							</tr>
						</template>

						<template v-else-if="activeRowId != null && urlMapping && urlMapping.length > 0">
							<tr v-for="(row, index) in urlMapping" :key="index">
								<td width="10%" class="p-2 border-top-light-grey custome-table-td">
									<!-- <ShowValue :object="row" object-key="id" label="id"></ShowValue>
										{{ per_page * (currentPage - 1) + index + 1 }} -->
									{{ index + 1 }}
								</td>
								<td width="20%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue :object="row" object-key="keyword" label="keyword"></ShowValue>
								</td>
								<td width="20%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue
										:object="row"
										object-key="target_url"
										label="target_url"
										:capitalize="false"
									></ShowValue>
								</td>
								<td width="6%" class="p-2 border-top-light-grey custome-table-td">
									<div class="d-flex justify-center">
										<v-chip
											v-if="row.status"
											class="text-capitalize white--text rounded-sm"
											:color="statusColor(row.status)"
											width="70"
											>{{ row.status }}</v-chip
										>
									</div>
								</td>
								<td width="15%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue :object="row" object-key="current_title" label="title"></ShowValue>
								</td>
								<td width="20%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue :object="row" object-key="current_description" label="description"></ShowValue>
								</td>
								<td width="22%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue :object="row" object-key="current_h1" remove-tags label="h1"></ShowValue>
								</td>
								<!-- <td width="10%" class="p-2 border-top-light-grey custome-table-td">
											<ShowValue :object="row" object-key="top_20" label="top_20"></ShowValue>
										</td> -->

								<!-- <td width="10%" class="p-2 border-top-light-grey custome-table-td">
											<ShowValue :object="row" object-key="top_10" label="top_10"></ShowValue>
										</td>
										<td width="10%" class="p-2 border-top-light-grey custome-table-td">
											<ShowValue :object="row" object-key="top_20" label="top_5"></ShowValue>
										</td>
										<td width="10%" class="p-2 border-top-light-grey custome-table-td">
											<ShowValue :object="row" object-key="top_10" label="top_3"></ShowValue>
										</td> -->
							</tr>
						</template>

						<template v-else>
							<tr>
								<td :colspan="7">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There <span class="text-lowercase">are no file at the moment.</span>
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</v-simple-table>
			</div>
			<!-- <Table
						type="seo_analysis"
						delete-endpoint="seo_analysis/"
						v-on:reload:content="filterRows"
						v-on:update:dialog="getInvoicedata"
						delete-note="All transactions of this member will also be deleted."
					></Table> -->
			<ImportDialog
				endpoint="seo-url-mapping/import"
				type="url_mapping"
				title="URL Mapping"
				v-if="importDialog"
				:import-dialog.sync="importDialog"
				v-on:close="importDialog = false"
				v-on:refress="getmapExcel"
				:project-data-id="projectId"
				:seo-data-id="seoId"
				sample-file="/media/sample-files/Sample_url_mapping.xlsx"
			></ImportDialog>
			<DeleteTemplate
				v-if="deleteDialog"
				type="URL Mapping"
				delete-text="Keyword"
				v-on:success="getmapExcel()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="`seo/project/keyword-analysis/${id}?type=url_mapping`"
			>
			</DeleteTemplate>
			<!-- <v-row class="my-2" style="margin: 0 !important">
					<v-col md="6" class="my-auto">
						<p class="m-0">{{ showingString }}</p>
					</v-col>
					<v-col md="6" class="text-right">
						<v-pagination
							color="blue darken-4"
							v-model="page"
							:length="totalPage"
							total-visible="9"
							v-on:input="updatePagination($event)"
						></v-pagination>
					</v-col>
				</v-row> -->
		</div>
		<!-- </v-sheet> -->
	</div>
</template>
<style>
.custome-table {
	background-color: #f3f6f9;
	border-bottom: thin solid rgba(0, 0, 0, 0.12);
	border-right: 1px solid #e2e5ec !important;
}
.custome-table-td {
	border-right: 1px solid #e2e5ec !important;
}
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
	/*  border-bottom: solid 1px #bcc0c9 !important; */
}
.listing-select .v-select__selection--comma {
	margin-top: 0px;
	margin-bottom: 0px;
}

:is(.normal-table) thead tr th,
tbody tr td {
	font-size: 13px !important;
	color: rgba(0, 0, 0, 0.87) !important;
}

.normal-table thead tr th {
	font-weight: 500 !important;
}
.normal-table tbody tr td {
	font-weight: 400 !important;
}

.normal-table tbody tr:nth-child(odd) {
	background-color: #e7f4ff;
}

.seo-tabs .v-data-table__wrapper {
	max-height: 600px;
	/* min-height: 325px; */
	overflow-y: auto !important;
}

.table-detail .v-data-table__wrapper {
	overflow-y: auto !important;
}
</style>
<script>
/* import Dialog from "@/view/components/Dialog"; */
//import ListingMixin from "@/core/mixins/listing.mixin";
/* import { QUERY } from "@/core/services/store/request.module";
import { SET_TBODY } from "@/core/services/store/listing.module"; */
import ImportDialog from "@/view/components/ImportDialogSEOTabs.vue";
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import JwtService from "@/core/services/jwt.service";
import HeightMixin from "@/core/mixins/height.mixin";
import MainMixin from "@/core/mixins/main.mixin";
import { SET_MESSAGE } from "@/core/services/store/common.module";

// import {
// 	SET_CURRENT_PAGE,
// 	SET_SHOWING_STRING,
// 	SET_TOTAL_PAGE,
// } from "@/core/services/store/listing.module";

export default {
	name: "Seo-Analysis-listing",
	title: "URL Mapping",
	mixins: [MainMixin, HeightMixin],
	data() {
		return {
			skipBottomHeight: 56,
			filter: {
				month: "",
				year: new Date().getFullYear().toString(),
				search: null,
			},
			excelHeight: (document.body.clientHeight / 100) * 35,
			mappingHeight: (document.body.clientHeight / 100) * 35,
			month_menu: false,
			year_menu: false,
			yearList: [],
			monthList: [
				{ index: 0, title: "All MONTH", value: "" },
				{ index: 1, title: "January", value: "01" },
				{ index: 2, title: "February", value: "02" },
				{ index: 3, title: "March", value: "03" },
				{ index: 4, title: "April", value: "04" },
				{ index: 5, title: "May", value: "05" },
				{ index: 6, title: "June", value: "06" },
				{ index: 7, title: "July", value: "07" },
				{ index: 8, title: "August", value: "08" },
				{ index: 9, title: "September", value: "09" },
				{ index: 10, title: "October", value: "10" },
				{ index: 11, title: "November", value: "11" },
				{ index: 12, title: "December", value: "12" },
			],

			pageTitle: "Seo-Analysis",
			pageBreadcrumbs: [{ text: "Seo-Analysis", disabled: true }],
			activeRowId: 1,
			endpoint: "seo-analysis",
			defaultFilter: {},
			count: {},
			status: "all",
			pageLoading: false,
			importDialog: false,
			urlMapping: [],
			mapExcel: [],

			page: 1,
			showing_string: null,
			totalRows: 0,
			rowsOffset: 0,
			totalPages: 0,
			totalSelected: 0,
			paginationPageSize: 0,
			per_page: 6,
			id: null,
			deleteDialog: false,
			exportCSVUrl: null,
			sort: {
				column: null,
				icon: "mdi-filter-variant",
				order: null,
			},
			usort: {
				column: null,
				icon: "mdi-filter-variant",
				order: null,
			},
			timer: null,
			// seoId: null
		};
	},
	props: {
		customerId: {
			type: Number,
			default: 0,
		},
		projectId: {
			type: [Number, String],
			default: 1,
		},
		contractId: {
			type: Number,
			default: 0,
		},
		projectTitle: {
			type: String,
			default: "",
		},
		url: {
			type: String,
			default: "",
		},
		fileName: {
			type: String,
			default: null,
		},
	},
	/* watch: {
		relatedId() {
			this.getListing();
		},
		updatedData() {
			this.getListing();
		},
	}, */
	watch: {
		// "filter.month": {
		// 	handler: function () {
		// 		this.getURLMapping();
		// 	},
		// },
		// "filter.year": {
		// 	handler: function () {
		// 		this.getURLMapping();
		// 	},
		// },
		"filter.search": {
			handler: function () {
				let run = debounce(function () {
					this.getURLMapping();
				}, 1000);
				run();
			},
		},
	},
	methods: {
		searchKeyword() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.getURLMapping();
			}, 600);
		},
		fetchStatus() {
			this.pageLoading = true;
			ApiService.get(`get-url-status/${this.activeRowId}`)
				.then(() => {
					// this.urlMapping = data.data;
					this.getURLMapping();
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Updated Successfully",
						},
					]);
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		sortBacklink(column, sortType, table, occ = "") {
			let icon, order;
			if (occ == "") {
				icon = sortType == "desc" ? "mdi-sort-ascending" : "mdi-sort-descending";
				order = sortType == "desc" ? "asc" : "desc";
				const { query, params, name } = this.$route;
				this.$router.push({ name, params, query: { ...query, [table]: `${name}:${column}:${order}` } });
				/* throw at the last in call stack */
				if (table == "sort") {
					setTimeout(() => this.getURLMapping(), 0);
				} else if (table == "usort") {
					setTimeout(() => this.getmapExcel(), 0);
				}
			} else {
				icon = sortType == "desc" ? "mdi-sort-descending" : "mdi-sort-ascending";
				order = sortType;
			}
			this[table].column = column;
			this[table].icon = icon;
			this[table].order = order;
		},
		rowActive(id) {
			if (id != this.activeRowId) {
				this.activeRowId = id;
				this.getURLMapping();
			}
		},
		statusColor(value) {
			return +value >= 200 && +value < 300
				? "green"
				: +value >= 300 && +value < 400
				? "orange"
				: "red";
		},
		doAction(row, param) {
			switch (param) {
				case "download":
					this.exportCSV(row.url);
					break;
				case "delete":
					this.id = row.id;
					this.deleteDialog = true;
					break;
			}
		},
		downloadSample() {
			var anchor = document.createElement("a");
			anchor.setAttribute("href", "./url-mapping.xlsx");
			anchor.setAttribute("download", "");
			document.body.appendChild(anchor);
			anchor.click();
			anchor.parentNode.removeChild(anchor);
		},
		exportCSV(url = null) {
			ApiService.setHeader();
			let downloadURL = url
				? url
				: this.activeRowId
				? process.env.VUE_APP_API_URL +
				  `seo/${this.seoId}/project/${this.projectId}/url-mapping-export?url_map_id=${this.activeRowId}`
				: "";
			downloadURL = new URL(downloadURL);
			const token = JwtService.getToken();
			downloadURL.searchParams.append("token", token);

			// const project_name = this.projectTitle;

			const customName =
				this.fileName + " - url mapping (" + this.formatDateTimeRawWithoutTime(new Date()) + ").csv";

			fetch(downloadURL)
				.then((response) => response.blob())
				.then((blob) => {
					const blobURL = URL.createObjectURL(blob);
					const anchor = document.createElement("a");
					anchor.href = blobURL;
					anchor.setAttribute("download", customName);
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
					URL.revokeObjectURL(blobURL);
				});
		},
		getURLMapping(activeId = this.activeRowId.toString()) {
			this.pageLoading = true;
			const payload = {
				url_map_id: activeId,
				sort_column: this.sort.column || null,
				sort_order: this.sort.order || null,
				status: null,
				// current_page: this.currentPage.toString(),
				per_page: 10000,
				search: this.filter.search || null,
			};

			ApiService.query(`seo/${this.seoId}/project/${this.projectId}/list-url-mapping`, payload)
				.then(({ data }) => {
					this.urlMapping = data;
					// this.seoAnalyses = data.tbody;
					// this.showing_string = data.showing_String;
					// this.total_page = data.totalPages;
					// this.$store.commit(SET_SHOWING_STRING, this.showing_string);
					// this.$store.commit(SET_TOTAL_PAGE, this.total_page);
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getmapExcel() {
			this.pageLoading = true;
			const payload = {
				sort_column: this.usort.column || null,
				sort_order: this.usort.order || null,
				...this.filter,
			};
			ApiService.query(`seo/${this.seoId}/project/${this.projectId}/excel-url-mapping`, payload)
				.then(({ data }) => {
					this.mapExcel = data;
					// if (data.length > 0) {
					let id = data && data.length > 0 && data[0].id;
					this.activeRowId = id;
					this.getURLMapping();
					// } else {
					// 	this.activeRowId = null;
					// }
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		// updatePagination(param) {
		// 	if (this.currentPage == param) {
		// 		return false;
		// 	}

		// 	// this.$store.commit(SET_CURRENT_PAGE, param);
		// 	this.$nextTick(() => {
		// 		this.getURLMapping();
		// 	});
		// },
		onEsc() {
			this.resetSearch();
		},
		resetSearch() {
			const _this = this;
			_this.$nextTick(() => {
				_this.filter.search = null;
				this.getURLMapping();
			});
		},
		getYears() {
			let current_year = +new Date().toISOString().slice(0, 4);
			let _yearList = [];
			while (_yearList.length < 51) {
				let obj = {
					title: current_year.toString(),
					index: _yearList.length,
					value: current_year.toString(),
				};
				_yearList.push(obj);
				current_year = current_year - 1;
			}
			this.yearList = [..._yearList];
		},
	},
	components: {
		ImportDialog,
		ShowValue,
		Chip,
		DeleteTemplate,
	},
	mounted() {
		let seoId = this.$route.params.id;
		if (seoId) {
			this.seoId = seoId;
			this.getmapExcel();
		}
		this.getYears();
	},
	beforeMount() {
		const { query } = this.$route;
		const sort = query.sort;
		const usort = query.usort;
		if (sort) {
			const sortArr = sort.split(":");
			this.sortBacklink(sortArr[1], sortArr[2], "sort", "beforeMount");
		}
		if (usort) {
			const sortArr = usort.split(":");
			this.sortBacklink(sortArr[1], sortArr[2], "usort", "beforeMount");
		}
	},
	computed: {
		...mapGetters(["selected", "tbody", "currentPage", "totalPage", "showingString"]),
	},
};
</script>
